import React from "react";
import { StaticQuery, graphql } from "gatsby";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem";
import Img from "gatsby-image";
import classNames from "classnames";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Close from "@material-ui/icons/Close";
import Button from "components/CustomButtons/Button.jsx";
import Pagination from "components/Pagination/Pagination.jsx";
import getPages from "util/getPages.jsx";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import mediaCenterStyle from "ps-assets/jss/mediaCenterStyle.jsx";

import PropTypes from "prop-types";
function Transition(props) {
  return <Slide direction="down" {...props} />;
}

const fieldsPerPage = 6;

class mediaCenterAwards extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      image: {},
      currentPage: 0
    };
  }

  changeCurrentPage = newPage => {
    this.setState({ currentPage: newPage });
  };

  handleClickOpen = image => {
    // var x = [];
    // x[modal] = true;
    // e.preventDefault();
    this.setState({
      showModal: true,
      image: image
    });
  };
  handleClose() {
    // var x = [];
    // x[modal] = false;
    this.setState({
      showModal: false
    });
  }

  render() {
    const { classes } = this.props;
    const { currentPage } = this.state;
    return (
      <>
        <StaticQuery
          query={graphql`
            query AwardsMediaCenterQuery {
              allStrapiEntities(
                filter: { category: { eq: "awards" } }
                sort: { fields: sequence, order: DESC }
              ) {
                edges {
                  node {
                    id
                    title
                    description
                    url
                    Image {
                      childImageSharp {
                        fluid(maxWidth: 700, quality: 100) {
                          ...GatsbyImageSharpFluid_withWebp
                        }
                      }
                    }
                  }
                }
              }
            }
          `}
          render={data => (
            <div>
              <div className={classes.container}>
                <GridContainer>
                  <GridItem
                    md={10}
                    sm={10}
                    className={classNames(classes.mrAuto, classes.mlAuto)}
                  >
                    <h2
                      className={classNames(classes.title, classes.textCenter)}
                    >
                      Awards
                    </h2>
                  </GridItem>
                  {data.allStrapiEntities.edges
                    .filter(v => v.node.Image !== null)
                    .slice(
                      currentPage * fieldsPerPage,
                      (currentPage + 1) * fieldsPerPage
                    )
                    .map((document, idx) => {
                      return (
                        <>
                          <GridItem key={idx} xs={12} sm={12} md={6}>
                            <Card
                              className={classes.card}
                              plain
                              style={{
                                textAlign: `left`,
                                background: `#FFFFFF`,
                                minHeight: `175px`
                              }}
                            >
                              <GridContainer>
                                <GridItem xs={12} sm={5} md={4}>
                                  <CardHeader
                                    image
                                    style={{ marginTop: `25px` }}
                                  >
                                    <a
                                      rel="noopener noreferrer"
                                      href="#"
                                      onClick={() =>
                                        this.handleClickOpen(
                                          document.node.Image.childImageSharp
                                            .fluid
                                        )
                                      }
                                      className={classes.awardHeader1}
                                    >
                                      {document.node.Image && (
                                        <Img
                                          fluid={
                                            document.node.Image.childImageSharp
                                              .fluid
                                          }
                                          alt="..."
                                          style={{
                                            boxShadow: `none`,
                                            margin: `0 auto`
                                          }}
                                        />
                                      )}
                                    </a>
                                  </CardHeader>
                                </GridItem>
                                <GridItem xs={12} sm={7} md={8}>
                                  <CardBody
                                    style={{
                                      paddingTop: 0
                                    }}
                                  >
                                    <h4 className={classes.cardTitle}>
                                      {document.node.title}
                                    </h4>
                                    <p
                                      className={classNames(
                                        classes.description
                                      )}
                                      dangerouslySetInnerHTML={{__html: [document.node.description]}}
                                    >
                                    </p>
                                  </CardBody>
                                </GridItem>
                              </GridContainer>
                            </Card>
                          </GridItem>
                        </>
                      );
                    })}

                  <Dialog
                    classes={{
                      root: classes.modalRoot,
                      paper: classes.modalAwards
                    }}
                    open={this.state.showModal}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={() => this.handleClose()}
                    aria-labelledby="classic-modal-slide-title"
                    aria-describedby="classic-modal-slide-description"
                  >
                    <DialogTitle
                      id="classic-modal-slide-title"
                      disableTypography
                      className={classes.modalHeader}
                    >
                      <Button
                        simple
                        className={classes.modalCloseButton}
                        key="close"
                        aria-label="Close"
                        onClick={() => this.handleClose()}
                        style={{
                          color: `#000`,
                          float: `right`
                        }}
                      >
                        <Close className={classes.modalClose} />
                      </Button>
                    </DialogTitle>
                    <DialogContent
                      id="classic-modal-slide-description"
                      className={classes.modalBody}
                    >
                      <div style={{ textAlign: `center`, height: `100%` }}>
                        <Img
                          fluid={this.state.image}
                          alt="..."
                          style={{
                            width: 500,
                            boxShadow: `none`
                          }}
                        />
                      </div>
                    </DialogContent>
                  </Dialog>
                </GridContainer>
                <GridItem style={{ display: `flex` }} md={12}>
                  <Pagination
                    className={classNames(classes.mrAuto, classes.mlAuto,classes.paginationScroll)}
                    pages={getPages(
                      data.allStrapiEntities.edges,
                      currentPage,
                      fieldsPerPage,
                      this.changeCurrentPage
                    )}
                  />
                </GridItem>
              </div>
            </div>
          )}
        />
      </>
    );
  }
}

mediaCenterAwards.propTypes = {
  classes: PropTypes.object
};
export default withStyles(mediaCenterStyle)(mediaCenterAwards);
