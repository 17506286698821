import React from "react";
import { StaticQuery, graphql } from "gatsby";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import mediaCenterStyle from "ps-assets/jss/mediaCenterStyle.jsx";
import PropTypes from "prop-types";
import Accordion from "components/Accordion/Accordion.jsx";
// import { title } from "assets/jss/material-kit-pro-react";

const Committees_Board = ({ classes }) => {
  return(
  <StaticQuery
    query={graphql`
      query {
        allStrapiDocuments(filter: {Category: {eq: "committees_board"}}) {
          edges {
            node {
              Title
              link {
                title
                url
                type
                urlArray{
                  subFields{
                    title
                    url
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => (
      <GridContainer style={{marginLeft:'0px',marginRight:'0px',marginTop:'-3%',marginBottom:'2%'}}>
      {data.allStrapiDocuments.edges
          .sort(
            (edge1, edge2) =>
              parseInt(edge2.node.Title.split(`-`)[0]) -
              parseInt(edge1.node.Title.split(`-`)[0])
          )
          .map((document, i) => {
            return (
              <>
              {document.node.Title=='Committees of the Board' && 
              <GridItem
                md={12}
                sm={12}
                className={classNames(
                  classes.mrAuto,
                  classes.mlAuto,
                  classes.textCenter,
                  classes.paddingRemoveRegulatory
                )}
                key={`committees_board=${i}`}
              >
                <Accordion
                  activeColor="primary"
                  style={{marginBottom:'0px'}}
                  collapses={[
                    {
                      // ${String.fromCharCode(65 + i)}.
                      title: `
                      ${
                        document.node.Title
                      }`,
                      content: (<>{
                        document.node.link.map((linkInfo, idx) => {
                          return (
                            <a
                              key={idx}
                              href={linkInfo.url}
                              target="_blank"
                              className={classes.anchorStyle}
                              rel="noopener noreferrer"
                            >
                              {linkInfo.title}
                            </a>
                          );
                      })
                    }
                    </>)
                    }
                  ]}
                />
              </GridItem>
          }
              </>
            );
          })}
      </GridContainer>
    )}
  />
)};

export default withStyles(mediaCenterStyle)(Committees_Board);

Committees_Board.propTypes = {
  classes: PropTypes.object
};
