import React,{useState} from "react";
import { StaticQuery, graphql } from "gatsby";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import mediaCenterStyle from "ps-assets/jss/mediaCenterStyle.jsx";
import PropTypes from "prop-types";
import Accordion from "components/Accordion/Accordion.jsx";

const Material_Contracts = ({ classes }) => {
  const [showNewsPaper,toggleNewsPaper] = useState(false); 
  return(
  <StaticQuery
    query={graphql`
      query Material_Contracts {
        allStrapiDocuments(filter: {Category: {eq: "material_contracts"}}) {
          edges {
            node {
              Title
              link {
                title
                url
                type
                urlArray{
                  subFields{
                    title
                    url
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => (
      <GridContainer style={{marginLeft:'0px',marginRight:'0px'}} key={`material_contracts`}>
        {data.allStrapiDocuments.edges
          .sort(
            (edge1, edge2) =>
              parseInt(edge2.node.Title.split(`-`)[0]) -
              parseInt(edge1.node.Title.split(`-`)[0])
          )
          .map((document, i) => {
            return (
              <GridItem
                md={12}
                sm={12}
                className={classNames(
                  classes.mrAuto,
                  classes.mlAuto,
                  classes.textCenter,
                  classes.paddingRemove
                )}
                key={`material_contracts=${i}`}
              >
                <Accordion
                  activeColor="primary"
                  noBorderIcon={"rm_Margin"}
                  removeSummaryPadding={true}
                  collapses={[
                    {
                      title: `${String.fromCharCode(65 + i)}.${
                        document.node.Title
                      }`,
                      content: document.node.link.map((linkInfo, idx) => {
                        if( linkInfo.type == "sub_fields"){
                          return (
                            <>
                            <a
                              key={idx}
                              onClick={() => toggleNewsPaper(!showNewsPaper)}
                              style={{ paddingLeft: `2%`, cursor: `pointer` }}
                            >
                              {linkInfo.title}
                            </a>
                            { showNewsPaper &&
                            <div style={{ paddingLeft: `2%`,marginTop:'1%'}}>
                            {linkInfo.urlArray && linkInfo.urlArray.subFields.map((item,index)=>(
                              <div key={`sub-field-${index}`}>
                                <span>
                                  {((((item.url.replace('/sites/default/files/','')).replace('.pdf','')).replace('MD/','')).replace('MC/','')).toUpperCase()}.
                                </span>
                              <a
                              key={`sub-field-${index}`}
                              href={item.url}
                              target="_blank"
                              style={{ paddingLeft: `2%`}}
                              rel="noopener noreferrer"
                              >
                              {item.title}
                              </a>  </div>
                        ))}
                       </div>
                            }
                            </>
                          );
                        }else{
                          return (
                            <div style={{width:'100%',display:'block'}}>
                               <div style={{float:'left',width:'4%'}}>
                                  {((((linkInfo.url.replace('/sites/default/files/','')).replace('.pdf','')).replace('MD/','')).replace('MC/','')).toUpperCase()}.
                                </div>
                                <div style={{float:'left',width:'96%'}}>
                            <a
                              key={idx}
                              href={linkInfo.url}
                              target="_blank"
                              // style={{ paddingLeft: `2%` }}
                              rel="noopener noreferrer"
                            >
                              {linkInfo.title}
                            </a>
                            </div>
                            </div>
                          );
                        }
                       
                      })
                    }
                  ]}
                />
              </GridItem>
            );
          })}
      </GridContainer>
    )}
  />
)};

export default withStyles(mediaCenterStyle)(Material_Contracts);

Material_Contracts.propTypes = {
  classes: PropTypes.object
};
