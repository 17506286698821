import React from "react";
import classNames from "classnames";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import mediaCenterStyle from "ps-assets/jss/mediaCenterStyle.jsx";
import PropTypes from "prop-types";
import { StaticQuery, graphql } from "gatsby";
import { browserDocument,browserWindow } from "util/localStorageHelper";
import ModalForm from 'ps-components/ModalForm/ModalForm';
import Checkbox from "@material-ui/core/Checkbox";

class AuditedFinance extends React.Component {
    state={
        openRestatedFinance:false,
        acceptchecked:false,
        declinechecked:false,
        redirectionurl:''
    }
    toggleRestatedFinance=(url)=>{
        if (this.state.openRestatedFinance === false) {
          if (browserDocument.getElementById("mobileDrawer"))
            browserDocument.getElementById("mobileDrawer").style.zIndex = `1299`;
          if (browserDocument.getElementById("remy-thumbnail"))
            browserDocument.getElementById("remy-thumbnail").style.zIndex = `10`;
        } else {
          if (browserDocument.getElementById("mobileDrawer"))
            browserDocument.getElementById("mobileDrawer").style.zIndex = `9999999999`;
          if (browserDocument.getElementById("remy-thumbnail"))
            browserDocument.getElementById("remy-thumbnail").style.zIndex = `99999`;
        }
        this.setState({
          openRestatedFinance:!this.state.openRestatedFinance,
          redirectionurl:url
        })
      }
    handleacceptCheckboxChange=()=>{
        let boxchecked=!this.state.acceptchecked
        this.setState({
            acceptchecked:boxchecked
        })
        if(boxchecked==true){
          browserWindow.location.href=this.state.redirectionurl
        }
    }
    handledeclineCheckboxChange=()=>{
        let boxchecked=!this.state.declinechecked
        this.setState({
          checdeclinecheckedked:boxchecked
        })
        if(boxchecked==true){
          browserWindow.location.href=process.env.BASE_URL
        }
    }
    componentDidMount(){
        let recaptchaScript = browserDocument.createElement('script');
        recaptchaScript.src = `https://www.google.com/recaptcha/api.js`;
        browserDocument.head.appendChild(recaptchaScript);
      }
    render(){
        const {classes}=this.props

        return(
            <GridContainer style={{marginLeft:'0px',marginRight:'0px'}} key={`RestatedFinance`}>
                {this.state.openRestatedFinance && (
                    <>
                    <ModalForm
                    show={this.state.openRestatedFinance}
                    customStyle={`freeQuote`}
                    handleClose={this.toggleRestatedFinance}
                    zIndex={true}
                    reduceWidth={true}
                  >
                <GridContainer className={classes.containerDisplay}>
                    <div className={classes.RestatedFinancePosition}>
                    <h2 style={{textAlign:'center'}}>DISCLAIMER</h2>
        
          
            <GridItem
                md={11}
                sm={11}
                xs={11}
                className={classNames(classes.mrAuto, classes.mlAuto, classes.infoArea,classes.handleMarginRight)}
            >
                <h5>
                The audited financial statements of Star Health And Allied Insurance Company Limited (“<b>Company</b>”), for the Financial Years 2019, 2020 and 2021 and the audit reports thereon dated 30th May 2019, 12th June 2020 and 30th April 2021, respectively (collectively, the “<b>Financial Statements</b>”), have been uploaded here by the Company solely to comply with the requirements specified in the Securities and Exchange Board of India (Issue of Capital and Disclosure Requirement) Regulations, 2018, as amended (“<b>SEBI ICDR Regulations</b>”).
                </h5>

                <h5>
                Please note that the Financial Statements do not constitute a draft red herring prospectus, red herring prospectus or final prospectus, a statement in lieu of a prospectus, an offering circular, an offering memorandum, an advertisement, an offer or a solicitation of any offer or an offer document to purchase or sell any securities under the Companies Act, 2013, the SEBI ICDR Regulations, or any other applicable law in India or elsewhere in the world. The Financial Statements should not be considered as part of information that any investor should consider to subscribe for or purchase any securities of the Company and should not be relied upon or used as a basis for any investment decision. Anyone placing reliance on the Financial Statements, or any other information on the website of the Company would be doing so at their own risk.
                </h5>

                <h5>
                The information contained herein does not constitute an offer for sale of, or solicitation of an offer to buy, the securities of the Company in the United States or in any other jurisdiction. Securities may not be offered or sold in the United States absent registration or an exemption from registration under the U.S. Securities Act of 1933, as amended.
                </h5>

                <h5>
                While care has been taken in preparing the Financial Statements, none of the Company or any of its advisors, nor any book running lead managers or selling shareholders, nor any of their respective employees, directors, affiliates, agents or representatives accept any liability whatsoever for any loss howsoever arising from any information presented or contained in the Financial Statements, or the opinions expressed therein. None of the foregoing shall be liable for any direct or indirect losses arising from the use thereof and the viewers are requested to use the information contained therein at their own risk.
                </h5>

                <h5>
                These Financial Statements should not be copied, reproduced, re-circulated, re-distributed, published or advertised in any media, website or otherwise, in any form or manner, in part or as a whole, without the express consent in writing from the Company. Any unauthorized use of the Financial Statements contained herein is prohibited. Accordingly, any persons in possession of the aforesaid should inform themselves about and observe any such restrictions. Information contained in the Financial Statements is provided “as is” without warranty of any kind, either expressed or implied, including any warranty of fitness for a particular purpose. No reliance should be placed on, the accuracy, fairness or completeness of the information presented or contained in these Financial Statements. Further, past performance is not necessarily indicative of future results. Any opinion expressed on the Financial Statements are subject to change without notice.
                </h5>

                <h5>
                The Financial Statements are only current as of the dates specified therein and neither the Company nor any of its respective advisors or representatives is under any obligation to update you in the event the information in the Financial Statements becomes stale or inaccurate. You must make your own assessment of the relevance, accuracy and adequacy of the information contained in the Financial Statements and must make such independent investigation as you may consider necessary or appropriate for such purpose.
                </h5>
                <div>
                </div>
                <div style={{marginTop:'2%'}}>
                <Checkbox
                onChange={this.handleacceptCheckboxChange}
                disableRipple
                style={{ color: `#000066`,padding:'0px',
                paddingRight: '7px',
                paddingBottom: '3px' }}
                disabled={this.state.declinechecked}
                /> <span>
                <b>I have read and accept</b>
                </span>
                </div>
                <div style={{marginTop:'1%'}}>
                <Checkbox
                onChange={this.handledeclineCheckboxChange}
                disableRipple
                style={{ color: `#000066`,padding:'0px',
                paddingRight: '7px',
                paddingBottom: '3px' }}
                disabled = {this.state.acceptchecked}
                /> <span>
                <b>I do not accept</b>
                </span>
                </div>
                <GridItem  style={{ paddingLeft:'0px'}}>
            </GridItem>
            </GridItem>
            </div>
            </GridContainer>
            
            </ModalForm>
            </>
        )}
        <StaticQuery
          query={graphql`
            query RestatedFinanceQuery {
              allStrapiDocuments(filter: { Category: { eq: "audited_finance" } }) {
                edges {
                  node {
                    Title
                    link {
                      title
                      url
                    }
                  }
                }
              }
            }
          `}
          render={data => (
            <GridContainer md={12} sm={12} xs={12} key={`restated-finance`}>
              {data.allStrapiDocuments.edges
                .sort(
                  (edge1, edge2) =>
                    parseInt(edge2.node.Title.split(`-`)[0]) -
                    parseInt(edge1.node.Title.split(`-`)[0])
                )
                .map((document) => {
                  return (
                    <>
                      {document.node.link.map((linkInfo, index) => {
                        return (
                          <a
                            key={index}
                            onClick={()=>{
                              this.toggleRestatedFinance(linkInfo.url)
                            }}
                            rel="noopener noreferrer"
                            target="_blank"
                            className={linkInfo.title=='FY 2018-19' ? classes.cnonpaddingLeft : classes.cpaddingLeft}
                            style={{ cursor:'pointer',float:'left' }}
                          >
                            {linkInfo.title}
                          </a>
                        );
                      })}
                    </>
                  );
                })}
            </GridContainer>
          )}
        />
          </GridContainer>    
        )
    }
 
        };

export default withStyles(mediaCenterStyle)(AuditedFinance);

AuditedFinance.propTypes = {
  classes: PropTypes.object
};
