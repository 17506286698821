import React from "react";
import { graphql } from "gatsby";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import NavPills from "components/NavPills/NavPills.jsx";
import News from "./mediaCenterNews";
import Awards from "./mediaCenterAwards";
import PublicDisclosure from "./mediaCenterPublicDisclosure";
import MediaCampaigns from "./mediaCenterMediaCampaigns";
import HealthMagazine from "./mediaCenterHealthMagazine";
// import Newsletter from "./mediaCenterNewsletter";
import { Helmet } from "react-helmet";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import customStaticPageStyle from "ps-assets/jss/customStaticPageStyle.jsx";
import Layout from "ps-components/Layout/Layout.jsx";
import PropTypes from "prop-types";

// const mediaCenter_meta_tags = [
//   {
//     title: `News Center | StarHealth.in`,
//     description: `Visit our Media Campaign Page to explore our marketing videos.`
//   },
//   {
//     title: `Our Awards | StarHealth.in`,
//     description: `Awards and Achievements of StarHealth.in, thanks for our customers who helped us to garb these awards and to achieve a lot of things in Health Insurance Industry.`
//   },
//   {
//     title: `Media Campaign, Video Campaign, Marketing Campaign | StarHealth.in`,
//     description: `Visit our Media Campaign Page to explore our marketing videos.`
//   },
//   {
//     title: `Public Disclosure | StarHealth.in`,
//     description: `Star Health Insurance Public Disclosure. Explore here to get more information on StarHealh.in`,
//     keywords: `Star Health And Allied Insurance Company Limited, Star Health Care, Star Health Family Insurance, Star Health Insurance, Star Health Insurance Company, Star Health Insurance India, Star Health Insurance Plans, Star Health Insurance Policies, Buy Online Health Insurance Plans, Health Insurance, Accident Care, Overseas Travel, Insurance Policy`
//   },
//   {
//     title: `Health Magazine | StarHealth.in`
//   }
// ];

let url = process.env.BASE_URL;

class MediaCenter extends React.Component {
  // state = { currentTab: 0 };

  // changeMetaTag = newTab => {
  //   this.setState({
  //     currentTab: newTab
  //   });
  // };

  render() {
    const { classes, data, location } = this.props;
    // const { currentTab } = this.state;
    var Images = data.allStrapiImages.edges;
    var bwImage = Images[0];
    var clrImage = Images[1];
    var navPillArr = data.allStrapiSections.edges.map((document, i) => {
      var obj = {};
      let content;
      switch (document.node.strapiId) {
        case 209:
          content = <News key={`news-${i}`} />;
          break;
        case 210:
          content = <Awards key={`awards-${i}`} />;
          break;
        case 211:
          content = <MediaCampaigns key={`media-campaign-${i}`} />;
          break;
        case 212:
          content = <PublicDisclosure key={`public-disclosure-${i}`} />;
          break;
        case 274:
          content = <HealthMagazine key={`health-magazine-${i}`} />;
          break;
        // case 491:
        //   content = <Newsletter key={`newsletter-${i}`} />;
        //   break;
      }

      obj.tabButton = document.node.title;
      obj.tabImage = document.node.icon && document.node.icon.publicURL;
      obj.tabContent = content;
      return obj;
    });
    return (
      <Layout
        image1={
          bwImage &&
          bwImage.node &&
          bwImage.node.content &&
          bwImage.node.content.childImageSharp.fluid
        }
        image2={
          clrImage &&
          clrImage.node &&
          clrImage.node.content &&
          clrImage.node.content.childImageSharp.fluid
        }
        small={true}
        title={``}
        summary={``}
      >
        <Helmet key="helmetTags">
        <title>Media Center | StarHealth.in</title>
      </Helmet>
      {process.env.ENABLE_HELMET == `true` && (
        <Helmet key="helmetTags">
          <link
            rel="canonical"
            href={url + `media-center`}
          />
          <meta name="title" content="Media Center | StarHealth.in" />
          <meta name="twitter:title" content="Media Center | StarHealth.in" />
          <meta
            name="description"
            content="Star Health Insurance Media Center, Visit our Media Center Page to explore our marketing videos."
          />
          <meta
            name="keywords"
            content="Buy Online Health Insurance Plans, Health Insurance, Accident Care, Overseas Travel, Insurance Policy, Insurance Agent Corner, Star Health and Allied Insurance Company Limited, Chennai, India, Portability, Star Health Products, Renew  Policy Online,Star Health Locate us, Health Insurance Specialist In India."
          />
          <meta property="og:title" content="Media Center | StarHealth.in" />
          <meta property="og:description" content="Star Health Insurance Media Center, Visit our Media Center Page to explore our marketing videos." />
          <meta property="og:url" content={url + `media-center`}/>
          <meta name="Media Center | StarHealth.in" />
          <meta name="twitter:description" content="Star Health Insurance Media Center, Visit our Media Center Page to explore our marketing videos." />
          <meta property="twitter:url" content={url + `media-center`} />
        </Helmet>
      )}
        <div
          className={classNames(classes.main, classes.mainRaised)}
          style={{ marginBottom: 100 }}
        >
          <div className={classes.section}>
            <div className={classes.container}>
              <GridContainer>
                <GridItem style={{ paddingTop: `70px` }}>
                  <NavPills
                    changeMetaTag={this.changeMetaTag}
                    color="primary"
                    location={location}
                    tabs={navPillArr}
                    alignCenter
                    variant
                  />
                </GridItem>
              </GridContainer>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}
MediaCenter.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.object,
  location: PropTypes.object
};

MediaCenter.checkIfName = `yes.. name allowed`;

export default withStyles(customStaticPageStyle)(MediaCenter);

export const mediaCenterQuery = graphql`
  query mediaCenter {
    allStrapiSections(
      filter: { category: { eq: "media_center" } }
      sort: { fields: sequence, order: ASC }
    ) {
      edges {
        node {
          title
          strapiId
          icon {
            publicURL
          }
        }
      }
    }
    allStrapiImages(filter: { section: { category: { eq: "media" } } }) {
      edges {
        node {
          id
          sequence
          content {
            publicURL
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`;
