import React from "react";
import { StaticQuery, graphql } from "gatsby";
import classNames from "classnames";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import mediaCenterStyle from "ps-assets/jss/mediaCenterStyle.jsx";
import PropTypes from "prop-types";
import Accordion from "components/Accordion/Accordion.jsx";

const VotingDisclosure = ({ classes }) => (
  <StaticQuery
    query={graphql`
      query VotingDisclosure {
        allStrapiDocuments(filter: { Category: { eq: "voting_disclosure" } }) {
          edges {
            node {
              Title
              link {
                title
                sequence
                url
              }
            }
          }
        }
      }
    `}
    render={data => (
      <GridContainer style={{marginLeft:'0px',marginRight:'0px'}} key={`votingdisclosure`}>
        {data.allStrapiDocuments.edges
          .sort(
            (edge1, edge2) =>
              parseInt(edge2.node.Title.split(`-`)[0]) -
              parseInt(edge1.node.Title.split(`-`)[0])
          )
          .map((document, i) => {
            return (
              document.node.Title !== "Voting Disclosure" && (
              <GridItem
                md={12}
                sm={12}
                className={classNames(
                  classes.mrAuto,
                  classes.mlAuto,
                  classes.textCenter,
                  classes.paddingRemove
                )}
                style={{marginBottom:'-25px'}}
                key={`votingdisclosure=${i}`}
              >
                <Accordion
                  activeColor="primary"
                  noBorderIcon={"true"}
                  removeSummaryPadding={true}
                  collapses={[
                    {
                      title: `${String.fromCharCode(65 + i)}.${
                        document.node.Title
                      }`,
                      content: document.node.link.sort((a,b) => a.sequence - b.sequence).map((linkInfo, idx) => {
                        //if(linkInfo.url.indexOf('financeByQuarter') == -1)
                        return (
                            <a
                              key={idx}
                              href={linkInfo.url}
                              target="_blank"
                              style={{ paddingLeft: `2%` }}
                              rel="noopener noreferrer"
                            >
                              {linkInfo.title}
                            </a>
                          );
                      })
                    }
                  ]}
                />
              </GridItem>)
            );
          })}
      </GridContainer>
    )}
  />
);

export default withStyles(mediaCenterStyle)(VotingDisclosure);

VotingDisclosure.propTypes = {
  classes: PropTypes.object
};
