import React from "react";
import { StaticQuery, graphql } from "gatsby";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import mediaCenterStyle from "ps-assets/jss/mediaCenterStyle.jsx";
import PropTypes from "prop-types";
import Accordion from "components/Accordion/Accordion.jsx";
// import { title } from "assets/jss/material-kit-pro-react";
import PdAccordionTree from "components/PdAccordion/PdAccordionTree";
import treedata from "jsons/BulletinBoard.json"

const NCD_Compliances = ({ classes }) => {
  return(
  <StaticQuery
    query={graphql`
      query {
        allStrapiDocuments(filter: {Category: {eq: "bulletin_board"}}) {
          edges {
            node {
              Title
              link {
                title
                url
                type
                sequence
                urlArray{
                  subFields{
                    title
                    url
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => (
      <GridContainer style={{marginLeft:'0px',marginRight:'0px',marginTop:'-3%',marginBottom:'2%'}}>
      {data.allStrapiDocuments.edges
          .sort(
            (edge1, edge2) =>
              parseInt(edge2.node.Title.split(`-`)[0]) -
              parseInt(edge1.node.Title.split(`-`)[0])
          )
          .map((document, i) => {
            return (
              <>
              {document.node.Title=='Bulletin Board' && 
              <GridItem
                md={12}
                sm={12}
                className={classNames(
                  classes.mrAuto,
                  classes.mlAuto,
                  classes.textCenter,
                  classes.paddingRemoveRegulatory
                )}
                key={`bulletin_board=${i}`}
              >
                <Accordion
                  activeColor="primary"
                  style={{marginBottom:'0px'}}
                  collapses={[
                    {
                      // ${String.fromCharCode(65 + i)}.
                      title: `
                      ${
                        document.node.Title
                      }`,
                      content: (<>
                      <PdAccordionTree treedata={treedata} />
                    </>)
                    }
                  ]}
                />
              </GridItem>
          }
              </>
            );
          })}
      </GridContainer>
    )}
  />
)};

export default withStyles(mediaCenterStyle)(NCD_Compliances);

NCD_Compliances.propTypes = {
  classes: PropTypes.object
};
