import React from "react";
import { StaticQuery, graphql } from "gatsby";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem";
import NavPills from "components/NavPills/NavPills.jsx";
import classNames from "classnames";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import mediaCenterStyle from "ps-assets/jss/mediaCenterStyle.jsx";
import PaginationView from "templates/PaginationView.jsx";

const mediaCenterNews = ({ classes }) => {
  
  return (
    <StaticQuery
      query={graphql`
        query NewsMediaCenterQuery {
          allStrapiSections(
            filter: { page: { eq: "media_center" }, category: { eq: "news" } }
          ) {
            group(field: year) {
              edges {
                node {
                  id
                  sequence
                  title
                  page
                  sub_category
                  category
                  summary
                  year
                  description
                  image {
                    publicURL
                    childImageSharp {
                      fixed(width: 150, height: 205) {
                        src
                        srcSet
                        base64
                        height
                        width
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={data => {
        let reversed = data.allStrapiSections.group.sort((a, b) => {
          return b.edges[0].node.year - a.edges[0].node.year;
        });
        return (
          <div key={`news`}>
            <div className={classes.container} style={{ overflow: `hidden` }}>
              <GridContainer>
                <GridItem
                  md={10}
                  sm={10}
                  className={classNames(
                    classes.mrAuto,
                    classes.mlAuto,
                    classes.textCenter
                  )}
                >
                  <h2 className={classes.title}>News</h2>
                </GridItem>
                <GridItem
                  md={12}
                  sm={12}
                  className={classNames(
                    classes.mrAuto,
                    classes.mlAuto,
                    classes.textCenter
                  )}
                >
                  <NavPills
                    color="primary"
                    variant
                    tabs={reversed.map(document => {
                      var obj = {};
                      obj.tabButton = document.edges[0].node.year;
                      obj.tabContent = (
                        <GridContainer md={12}>
                          <PaginationView
                            data={document.edges
                              .filter(v => v.node.image !== null)
                              .filter(k => k.node.summary !== null)
                              .sort(
                                (a, b) => a.node.sequence - b.node.sequence
                              )}
                            classes={classes}
                          />
                        </GridContainer>
                      );
                      return obj;
                    })}
                    alignCenter
                  />
                </GridItem>
              </GridContainer>
            </div>
          </div>
        );
      }}
    />
  );
};

mediaCenterNews.propTypes = {
  classes: PropTypes.object
};

export default withStyles(mediaCenterStyle)(mediaCenterNews);
