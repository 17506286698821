import React from "react";
import { StaticQuery, graphql } from "gatsby";
// nodejs library that concatenates classes
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import mediaCenterStyle from "ps-assets/jss/mediaCenterStyle.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Accordion from "components/Accordion/Accordion";

const CompaniesAct = ({ classes }) => (
  <StaticQuery
    query={graphql`
      query CompaniesActContent {
        allStrapiDocuments(
          filter: { Category: { eq: "companies_act" } }
          sort: { fields: Title }
        ) {
          edges {
            node {
              Title
              link {
                title
                url
              }
            }
          }
        }
      }
    `}
    render={data => (
      <>
        {data.allStrapiDocuments.edges
          .sort(
            (edge1, edge2) =>
              parseInt(edge2.node.Title.split(`-`)[0]) -
              parseInt(edge1.node.Title.split(`-`)[0])
          )
          .map((document,index) => {
            return (
              <>
                <GridContainer
                  xs={12}
                  md={12}
                  sm={12}
                  style={{ marginLeft: `0px`, marginRight: `0px` }}
                >
                  <Accordion
                  removeSummaryPadding={true}
                    collapses={[
                      {
                        title: `${String.fromCharCode(65+index)}.${document.node.Title}`,
                        content: (
                          <GridContainer>
                            {document.node.link.map((linkInfo, idx) => {
                              return (
                                <GridItem
                                  md={4}
                                  sm={4}
                                  xs={12}
                                  key={`${idx}`}
                                  // className={cssClass}
                                >
                                  <Card
                                    key={idx}
                                    style={{ height: 90 }}
                                    className={classes.card}
                                  >
                                    <CardBody>
                                      {/* <ReactSVG
                            src={withPrefix("/download.svg")}
                            style={{ width: 40, height: 40, margin: `0 auto` }}
                          /> */}
                                      <a
                                        key={idx}
                                        href={linkInfo.url}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style={{ paddingLeft: `2%` ,paddingBottom: '2%'}}
                                      >
                                        {linkInfo.title}
                                      </a>
                                    </CardBody>
                                  </Card>
                                </GridItem>
                              );
                            })}
                          </GridContainer>
                        )
                      }
                    ]}
                  />
                </GridContainer>
              </>
            );
          })}
      </>
    )}
  />
);
export default withStyles(mediaCenterStyle)(CompaniesAct);

CompaniesAct.propTypes = {
  classes: PropTypes.object
};
