const getPages = (arr, currentPage, fieldsPerPage, todoOnClick) => {
  let pagesLength = Math.ceil(arr.length / fieldsPerPage);
  let pages = [];
  pages.push({
    active: currentPage > 0,
    disabled: currentPage <= 0,
    text: `prev`,
    onClick: () => todoOnClick(currentPage - 1)
  });
  let startPage = 0,
    endPage = pagesLength - 1,
    otherPages = 3;
  if (currentPage + otherPages < endPage) {
    endPage = currentPage + otherPages;
  }
  if (currentPage - otherPages > startPage) {
    startPage = currentPage - otherPages;
  }
  let difference = endPage - startPage;
  if (difference !== otherPages * 2) {
    difference = otherPages * 2 - difference;
    startPage = startPage - difference > 0 ? startPage - difference : startPage;
    endPage =
      endPage + difference < pagesLength ? endPage + difference : endPage;
  }
  for (let i = startPage; i <= endPage; i++) {
    pages.push({
      active: i === currentPage,
      text: i + 1,
      onClick: () => todoOnClick(i)
    });
  }
  pages.push({
    active: currentPage < pagesLength - 1,
    disabled: currentPage >= pagesLength - 1,
    text: `next`,
    onClick: () => todoOnClick(currentPage + 1)
  });
  return pages;
};

export default getPages;
