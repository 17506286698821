import React from "react";
import { StaticQuery, graphql, Link } from "gatsby";
// nodejs library that concatenates classes
import classNames from "classnames";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Accordion from "components/Accordion/Accordion.jsx";
import Financials from "./Financials.jsx";
import Material_Contracts from "./Material_Contracts.jsx";
import AnnualReport from "./AnnualReport.jsx";
import Stewardship from "./Stewardship.jsx";
import QualitativeParam from "./QualitativeParam.jsx";
import HealthParameterService from "./HealthParameterService.jsx";
// import VotingDisclosure from "./VotingDisclosure.jsx";
import AuditedFinance from "./AuditedFinance.jsx";
import CompaniesAct from "./CompaniesAct.jsx";
import NCD_Compliances from "./NCD_Compliances"
import Bulletin_Board from "./Bulletin_Board.jsx";
import Committees_Board from "./Committees_Board";
import Equity_Compliances from "./Equity_Compliances.jsx";
import AnnualGeneralMeeting from "./AnnualGeneralMeeting.jsx";
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import mediaCenterStyle from "ps-assets/jss/mediaCenterStyle.jsx";

const mediaCenterPublicDisclosure = ({ classes }) => (
  <StaticQuery
    query={graphql`
      query mediaCenterPublicDisclosure {
        allStrapiSections(filter: { category: { eq: "public_disclosure" } },sort:{order:ASC,fields:sequence}) {
          edges {
            node {
              strapiId
              id
              title
            }
          }
        }
        allStrapiLinks(filter: { category: { eq: "others" } },sort:{order:ASC,fields:sequence}) {
          edges {
            node {
              id
              title
              url
              newTab
            }
          }
        }
      }
    `}
    render={data => (
      <div>
        <div className={classes.container}>
          <GridContainer>
            <GridItem
              md={12}
              sm={12}
              className={classNames(
                classes.mrAuto,
                classes.mlAuto,
                classes.textCenter,
                classes.rmmarginRegulatory
              )}
            >
              <h2 className={classes.title}>Public Disclosures</h2>
            </GridItem>
            <GridItem xs={12} sm={2} md={2}></GridItem>
            <GridItem xs={12} sm={8} md={8}>
              <Accordion
                activeColor="primary"
                collapses={data.allStrapiSections.edges.map(document => {
                  var obj = {};
                  let content;
                  switch (document.node.strapiId) {
                    case 748:
                      content = <Material_Contracts />;
                      break;
                    case 213:
                      content = <Financials />;
                      break;
                    case 214:
                      content = <AnnualReport />;
                      break;
                    case 976:
                      content = <AnnualGeneralMeeting />;
                      break;
                    case 215:
                      content = <CompaniesAct />;
                      break;
                    case 216:
                      content = <Link to="/agent-list">List Of Agents</Link>;
                      break;
                    case 343:
                      content = <Link to="/claim-proposal">Unclaimed Amount</Link>;
                      break;
                    case 346:
                      content = (
                        <>
                          {data.allStrapiLinks.edges.map((element, key) => {
                            return (
                              <a
                                target={element.node.newTab ? "_blank" : ""}
                                rel="noopener noreferrer"
                                href={element.node.url}
                                key={key}
                              >
                                <div>{element.node.title}</div>
                              </a>
                            );
                          })}
                        </>
                      );
                      break;
                    case 551:
                      content = <HealthParameterService />;
                      break;
                    case 696:
                      content = <QualitativeParam />;
                      break;
                    case 658:
                      content = <Stewardship />;
                      break;
                    // case 682:
                    //     content = <VotingDisclosure />;
                    //     break;
                    case 691:
                      content = <AuditedFinance />;
                      break;
                  }

                  obj.title = document.node.title;
                  obj.content = content;
                  return obj;
                })}
              />
              <Equity_Compliances/>
              <NCD_Compliances/>
              <Bulletin_Board/>
              <Committees_Board/>
            </GridItem>
            <GridItem xs={12} sm={2} md={2}></GridItem>
          </GridContainer>
        </div>
      </div>
    )}
  />
);
mediaCenterPublicDisclosure.propTypes = {
  classes: PropTypes.object
};
export default withStyles(mediaCenterStyle)(mediaCenterPublicDisclosure);
