import React, { useState } from "react";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card.jsx";
import Pagination from "components/Pagination/Pagination";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import PropTypes from "prop-types";
import DownloadIcon from "@material-ui/icons/GetApp";
import classNames from "classnames";
import KnowMoreIcon from "@material-ui/icons/Visibility";
import Img from "gatsby-image";
import getPages from "util/getPages.jsx";
import Muted from "components/Typography/Muted.jsx";
const searchRegExp = /<rupeeSumbol \/>/g

const fieldsPerPage = 6;

const PaginantionView = ({ classes, data }) => {
  const [currentPage, setCurrentPage] = useState(0);
  return (
    <>
      <>
        {data
          .filter(v => v.node.image !== null)
          .filter(k => k.node.summary !== null)
          .sort((a, b) => a.node.sequence - b.node.sequence)
          .slice(currentPage * fieldsPerPage, (currentPage + 1) * fieldsPerPage)
          .map((d, i) => {
            let id = d.node.id.split('').splice(9).join('')
            let headline = d.node.title && id > 793 && d.node.title.split(' - ')
            let CTA;
            if (d.node.sub_category === `download_and_knowmore`) {
              let labels = d.node.description.split(' | ')
              CTA = (
                <div>
                  <a
                    href={labels[1]}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <span>
                      <KnowMoreIcon style={{ verticalAlign: `bottom` }} />
                      {` `}
                      Know More
                    </span>
                  </a>&emsp;
                  <a
                    href={labels[0]}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <span>
                      <DownloadIcon style={{ verticalAlign: `bottom` }} />
                      {` `}
                      Download
                    </span>
                  </a>
                </div>
              );
            } else if (d.node.sub_category === `know_more`) {
              CTA = (
                <a
                  href={d.node.description}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <span>
                    <KnowMoreIcon style={{ verticalAlign: `bottom` }} />
                    {` `}
                    Know More
                  </span>
                </a>
              );
            } else {
              CTA = (
                <a
                  href={d.node.description}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <span>
                    <DownloadIcon style={{ verticalAlign: `bottom` }} />
                    {` `}
                    Download
                  </span>
                </a>
              );
            }
            return (
              <GridItem xs={12} sm={12} md={6} key={`tabs-${i}`}>
                <Card
                  className={classes.card}
                  plain
                  style={{
                    textAlign: `left`,
                    background: `#FFFFFF`
                  }}
                >
                  <GridContainer>
                    <GridItem xs={12} sm={5} md={4}>
                      <CardHeader image plain>
                        <a
                          rel="noopener noreferrer"
                          href={
                            d.node.sub_category === `know_more`
                              ? d.node.description
                              : d.node.image.publicURL
                          }
                          target="_blank"
                          className={classes.awardHeader1}
                        >
                          <Img
                            fixed={d.node.image.childImageSharp.fixed}
                            alt="newsImage"
                            style={{
                              height: 205,
                              width: 150,
                              boxShadow: `none`
                            }}
                          />
                        </a>
                      </CardHeader>
                    </GridItem>
                    <GridItem xs={12} sm={7} md={8}>
                      <CardBody
                        style={{
                          paddingTop: 0
                        }}
                      >
                        {headline ? <h4 className={classes.cardTitle}>{headline[0].replace(searchRegExp, '₹')}</h4> 
                        : <h4 className={classes.cardTitle}>{d.node.title.replace(searchRegExp, '₹')}</h4>}
                        <h3 className={classes.headline}>{headline[1]}</h3>
                        <Muted>
                          <h6 className={classes.cardCategory}>
                            {d.node.summary}
                          </h6>
                        </Muted>
                        <p className={classes.description}>
                            {CTA}
                        </p>
                      </CardBody>
                    </GridItem>
                  </GridContainer>
                </Card>
              </GridItem>
            );
          })}
      </>
      <GridContainer sm={12} xs={12} md={12}>
        <GridItem style={{ display: `flex` }}>
          <Pagination
            className={classNames(
              classes.mlAuto,
              classes.mrAuto,
              classes.paginationScroll
            )}
            pages={getPages(data, currentPage, fieldsPerPage, setCurrentPage)}
          />
        </GridItem>
      </GridContainer>
    </>
  );
};

PaginantionView.propTypes = {
  data: PropTypes.array,
  classes: PropTypes.object
};

export default PaginantionView;
