import React from "react";
import { StaticQuery, graphql } from "gatsby";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import mediaCenterStyle from "ps-assets/jss/mediaCenterStyle.jsx";

const AnnualGeneralMeeting = ({ classes }) => (
  <StaticQuery
    query={graphql`
      query AnnualGeneralMeetingQuery {
        allStrapiDocuments(filter: { Category: { eq: "annual_generalmeeting" } }) {
          edges {
            node {
              Title
              link {
                title
                url
              }
            }
          }
        }
      }
    `}
    render={data => (
      <GridContainer style={{marginLeft:'0px',marginRight:'0px'}} key={`annual_generalmeeting`}>
        {data.allStrapiDocuments.edges
          .sort(
            (edge1, edge2) =>
              parseInt(edge2.node.Title.split(`-`)[0]) -
              parseInt(edge1.node.Title.split(`-`)[0])
          )
          .map((document, i) => {
            return (
              <GridItem
                md={12}
                sm={12}
                className={classNames(
                  classes.mrAuto,
                  classes.mlAuto,
                )}
                key={`annual-report-${i}`}
              >
                {document.node.link.map((linkInfo, index) => {
                  return (
                    <a
                      key={index}
                      href={linkInfo.url}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      {`${String.fromCharCode(65 + i)}. ${linkInfo.title}`}
                    </a>
                  );
                })}
              </GridItem>
            );
          })}
      </GridContainer>
    )}
  />
);
AnnualGeneralMeeting.propTypes = {
  classes: PropTypes.object
};

export default withStyles(mediaCenterStyle)(AnnualGeneralMeeting);
