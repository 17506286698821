import React from "react";
import { StaticQuery, graphql } from "gatsby";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import mediaCenterStyle from "ps-assets/jss/mediaCenterStyle.jsx";

const QualitativeParam = ({ classes }) => (
  <StaticQuery
    query={graphql`
      query QualitativeParam {
        allStrapiDocuments(filter: { Category: { eq: "qualitativeparam" } }) {
          edges {
            node {
              Title
              link {
                title
                url
              }
            }
          }
        }
      }
    `}
    render={data => (
      <div key={`qualitativeparam`} className={classes.stewardship}>
        {data.allStrapiDocuments.edges
          .sort(
            (edge1, edge2) =>
              parseInt(edge2.node.Title.split(`-`)[0]) -
              parseInt(edge1.node.Title.split(`-`)[0])
          )
          .map((document,i) => {
            return (
              <div key={`qualitativeparam-${i}`}>
                {document.node.link.map((linkInfo, index) => {
                  return (
                    <a
                      key={index}
                      href={linkInfo.url}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      {`${linkInfo.title}`}
                    </a>
                  );
                })}
              </div>
            );
          })}
      </div>
    )}
  />
);
QualitativeParam.propTypes = {
  classes: PropTypes.object
};

export default withStyles(mediaCenterStyle)(QualitativeParam);
