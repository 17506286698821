import React from "react";
import { StaticQuery, graphql } from "gatsby";
// nodejs library that concatenates classes
// import classNames from "classnames";
// core components
// import GridContainer from "components/Grid/GridContainer.jsx";
// import GridItem from "components/Grid/GridItem.jsx";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import mediaCenterStyle from "ps-assets/jss/mediaCenterStyle.jsx";
import VotingDisclosureStewarship from "./VotingDisclosure-stewarship";

const Stewardship = ({ classes }) => (
  <StaticQuery
    query={graphql`
      query Stewardship {
        allStrapiDocuments(filter: { Category: { eq: "stewardship" } }) {
          edges {
            node {
              Title
              link {
                title
                url
              }
            }
          }
        }
      }
    `}
    render={data => (
      <div key={`stewardship`} className={classes.stewardship}>
        {data.allStrapiDocuments.edges
          .sort(
            (edge1, edge2) =>
              parseInt(edge2.node.Title.split(`-`)[0]) -
              parseInt(edge1.node.Title.split(`-`)[0])
          )
          .map((document,i) => {
            return (
              <div key={`stewardship-${i}`}>
                {document.node.link.map((linkInfo, index) => {
                  return (
                    <a
                      key={index}
                      href={linkInfo.url}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      {`${linkInfo.title}`}
                    </a>
                  );
                })}
                <div style={{paddingLeft: `0%`,display:'block'}}>
                <VotingDisclosureStewarship/>
                </div>
              </div>
            );
          })}
      </div>
    )}
  />
);
Stewardship.propTypes = {
  classes: PropTypes.object
};

export default withStyles(mediaCenterStyle)(Stewardship);
