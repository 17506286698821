import React from "react";
import { StaticQuery, graphql } from "gatsby";
import withStyles from "@material-ui/core/styles/withStyles";
import GridContainer from "components/Grid/GridContainer.jsx";
import mediaCenterStyle from "ps-assets/jss/mediaCenterStyle.jsx";
import classNames from "classnames";
import GridItem from "components/Grid/GridItem";
import VerticalCard from "ps-components/Card/VerticalCard.jsx";
import PropTypes from "prop-types";

const mediaCenterHealthMagazine = ({ classes }) => (
  <StaticQuery
    query={graphql`
      query mediaCenterHealthMagazine {
        allStrapiLinks(
          filter: { category: { eq: "health_magazine" } }
          sort: { order: DESC, fields: strapiId }
        ) {
          edges {
            node {
              strapiId
              title
              url
            }
          }
        }
      }
    `}
    render={data => {
      return (
        <div>
          <div className={classes.container}>
            <GridContainer>
              <GridItem
                md={10}
                sm={10}
                className={classNames(
                  classes.mrAuto,
                  classes.mlAuto,
                  classes.textCenter
                )}
              >
                <h2 className={classes.title}>Health Magazine</h2>
              </GridItem>
              {data.allStrapiLinks.edges.map((edge, k) => {
                return (
                  <GridItem key={k} xs={12} sm={12} md={4}>
                    <a
                      href={edge.node.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <VerticalCard
                        noShadow
                        onHoverEffect
                        description={edge.node.title}
                        alignItems="center"
                        style={{ marginTop: `15px`, marginBottom: `15px` }}
                        displayComponent={
                          k == 0 && (
                            <img
                              src={require(`ps-assets/img/new_white_bg.gif`)}
                              style={{
                                width: `40px`,
                                height: `40px`,
                                position: `absolute`,
                                alignSelf: `flex-end`
                              }}
                            />
                          )
                        }
                      />
                    </a>
                  </GridItem>
                );
              })}
            </GridContainer>
          </div>
        </div>
      );
    }}
  />
);

mediaCenterHealthMagazine.propTypes = {
  classes: PropTypes.object
};

export default withStyles(mediaCenterStyle)(mediaCenterHealthMagazine);
