import React from "react";
import { StaticQuery, graphql } from "gatsby";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem";
import classNames from "classnames";
import root from 'window-or-global';
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import mediaCenterStyle from "ps-assets/jss/mediaCenterStyle.jsx";
import PropTypes from "prop-types";
import Button from "components/CustomButtons/Button";
import { browserDocument } from "util/localStorageHelper";

let loadYT;
var media_id = [];

class mediaCenterCampaign extends React.Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    if (!loadYT) {
      loadYT = new Promise((resolve) => {
        const tag = browserDocument.createElement(`script`);
        tag.src = `https://www.youtube.com/iframe_api`;
        const firstScriptTag = browserDocument.getElementsByTagName(`script`)[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
        root.onYouTubeIframeAPIReady = () => resolve(root.YT);
      });
    }
    loadYT.then((YT) => {

      this.player = new YT.Player(this.youtubePlayerAnchor, {
        height: 157,
        width: `100%`,
        videoId: media_id[0],
        events: {
          onStateChange: this.onPlayerStateChange
        }
      });
      this.player1 = new YT.Player(this.youtubePlayerAnchor1, {
        height: 157,
        width: `100%`,
        videoId: media_id[1],
        events: {
          onStateChange: this.onPlayerStateChange
        }
      });

    });
  }

  onPlayerStateChange = (e) => {

    if (e.data === 1) {

      this.stopVideo(e.target.a.id);
    }
  }
  stopVideo = (player_id) => {

    if (player_id == `widget2`)
      this.player1.pauseVideo();
    else if (player_id == `widget4`)
      this.player.pauseVideo();
  }

  render() {
    const { classes } = this.props;

    var arr = [];
    arr.push(<div style={{ objectFit: `fill`, borderRadius: `10px` }} ref={(r) => { this.youtubePlayerAnchor = r; }}></div>);
    arr.push(<div style={{ objectFit: `fill`, borderRadius: `10px` }} ref={(r) => { this.youtubePlayerAnchor1 = r; }}></div>);
    return (
      <StaticQuery query={graphql`
      query mediaCenterCampaign {
        allStrapiLinks(filter: { category: { eq: "media_campaign" } }) {
          edges {
            node {
              title
              id
              url
              description
            }
          }
        }
      }`} render={(data) => {

        data.allStrapiLinks.edges.map((document) => {
          media_id.push(document.node.url.split(`=`)[1]);

        });

        return (
          <div>
            <div className={classes.container}>
              <GridContainer justify="center">
                <GridItem
                  md={12}
                  sm={12}
                  xs={12}
                  className={classNames(
                    classes.mrAuto,
                    classes.mlAuto,
                    classes.textCenter
                  )}>
                  <h2 className={classes.title}>Media Campaigns</h2>
                </GridItem>

                {data && data.allStrapiLinks.edges.map((document, index) => {
                  return (
                    <GridItem key={index} xs={12} sm={12} md={12} lg={10} className={classNames(
                      classes.mrAuto,
                      classes.mlAuto
                    )}>
                      <GridContainer style={{ padding: `20px 0px` }} >
                        <GridItem xs={12} sm={12} md={4} style={{ padding: `10px 0px` }} >
                          <div style={{ maxWidth: `330px`, maxHeight: `340px`, textAlign: `center` }}>
                            {arr[index]}
                            {/* <iframe style={{ objectFit: `fill`, borderRadius: `10px` }} src={document.node.url.replace(`watch?v=`, `embed/`)} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe> */}
                          </div>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={8} style={{ padding: `0px 20px` }} >
                          <a
                            href={document.node.url}
                            rel="noopener noreferrer"
                            target="_blank">
                            <h4 className={classes.cardTitle} style={{ fontSize: `20px` }}>
                              {document.node.title}
                            </h4>
                          </a>
                          <span style={{ textAlign: `left`, fontSize: `18px` }}>{document.node.description}</span>
                        </GridItem>
                      </GridContainer>
                    </GridItem>
                  );
                })}
                <GridItem
                  md={10}
                  sm={10}
                  className={classNames(
                    classes.mrAuto,
                    classes.mlAuto,
                    classes.textCenter
                  )}>
                  <Button round color="primary" href={`https://www.youtube.com/user/StarhealthInsurance/videos`} target="_blank">
                      View More
                  </Button>
                </GridItem>
              </GridContainer>
            </div>
          </div>
        );
      }} />
    );
  }
}

mediaCenterCampaign.propTypes = {
  classes: PropTypes.object,
  YTid: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  data: PropTypes.object
};
export default withStyles(mediaCenterStyle)(mediaCenterCampaign);
