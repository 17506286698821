import React from "react";
import { StaticQuery, graphql } from "gatsby";
import classNames from "classnames";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import mediaCenterStyle from "ps-assets/jss/mediaCenterStyle.jsx";
import PropTypes from "prop-types";
import Accordion from "components/Accordion/Accordion.jsx";

const HealthParameterService = ({ classes }) => (
  <StaticQuery
    query={graphql`
      query HealthParameterService {
        allStrapiDocuments(filter: { Category: { eq: "health_parameter" } }) {
          edges {
            node {
              Title
              link {
                title
                url
                sequence
              }
            }
          }
        }
      }
    `}
    render={data => (
      <GridContainer style={{marginLeft:'0px',marginRight:'0px'}} key={`HealthParameterService`}>
        {data.allStrapiDocuments.edges
          .sort(
            (edge1, edge2) =>
              parseInt(edge2.node.Title.split(`-`)[0]) -
              parseInt(edge1.node.Title.split(`-`)[0])
          )
          .map((document, i) => {
            return (
              <GridItem
                md={12}
                sm={12}
                className={classNames(
                  classes.mrAuto,
                  classes.mlAuto,
                  classes.textCenter,
                  classes.paddingRemove
                )}
                style={{marginBottom:'-25px'}}
                key={`HealthParameterService=${i}`}
              >
                <Accordion
                removeSummaryPadding={true}
                  activeColor="primary"
                  collapses={[
                    {
                      title: `${String.fromCharCode(65 + i)}.${
                        document.node.Title
                      }`,
                      content: document.node.link.sort((link1,link2) => link1.sequence - link2.sequence).map((linkInfo, idx) => {
                        //if(linkInfo.url.indexOf('financeByQuarter') == -1)
                        return (
                            <div key={idx}>
                            <a
                              href={linkInfo.url}
                              target="_blank"
                              style={{ paddingLeft: `2%` }}
                              rel="noopener noreferrer"
                            >
                              {linkInfo.title}
                            </a>
                            </div>
                          );
                      })
                    }
                  ]}
                />
              </GridItem>
            );
          })}
      </GridContainer>
    )}
  />
);

export default withStyles(mediaCenterStyle)(HealthParameterService);

HealthParameterService.propTypes = {
  classes: PropTypes.object
};
